import locales from '@quno/patient-journey/src/locales';
import config from '@quno/patient-journey/config';

export const getContentfulLocale = (locale?: string): string | undefined => {
  if (!locale) {
    return;
  }

  const localeInfo = config.locales.find((l) => l.main === locale);
  if (!localeInfo) {
    return;
  }

  return localeInfo.contentful;
};

export const getPathLocale = (
  contentfulLocale?: string,
): string | undefined => {
  if (!contentfulLocale) {
    return;
  }

  const localeInfo = config.locales.find(
    (l) => l.contentful === contentfulLocale,
  );

  if (!localeInfo) {
    return;
  }

  return localeInfo.main;
};

type RedirectIfLocaleNotFoundReturn =
  | {
      redirect: {
        destination: string;
        permanent: boolean;
      };
    }
  | undefined;

export const localeNotFound = (
  locale: string | undefined,
  resolvedUrl: string,
): RedirectIfLocaleNotFoundReturn => {
  const localeExists = locales[locale as keyof typeof locales];

  if (localeExists === undefined) {
    return {
      redirect: {
        destination: `/${config.defaultLocale}${resolvedUrl}`,
        permanent: false,
      },
    };
  }

  return undefined;
};
