import NextImage from 'next/image';
import Link from 'next/link';
import { useBrand } from '@quno/patient-journey/src/hooks/useBrand';
import { createCloudimgLoader } from '@quno/patient-journey/src/utils/cloudimg';
import styles from './Header.module.scss';

export type CtaProps = {
  icon?: {
    src?: string | null;
    alt?: string | null;
  };
  title?: string;
  subtitle?: string;
  href?: string;
};

export type HeaderProps = {
  cta?: CtaProps;
};

export const Header = ({ cta }: HeaderProps): JSX.Element => {
  const { logo } = useBrand() ?? {};

  const { icon, title, subtitle, href } = cta || {};

  const brandLogo = logo?.url ? (
    <NextImage
      src={logo.url}
      alt={logo.title || ''}
      loader={createCloudimgLoader()}
      width={logo.width!}
      height={logo.height!}
      style={{
        height: '40px !important',
      }}
      priority
    />
  ) : undefined;

  const ctaIcon = icon?.src ? (
    <NextImage
      src={icon.src}
      width={40}
      height={40}
      alt={title || ''}
      loader={createCloudimgLoader()}
    />
  ) : undefined;

  return (
    <header className={styles.header}>
      {brandLogo && (
        <div className={styles.logo}>
          <Link href="/">{brandLogo}</Link>
        </div>
      )}

      {ctaIcon && (
        <Link href={href!}>
          <div className={styles.contactUs}>
            <div>{ctaIcon}</div>
            <div className={styles.contactUsBlock}>
              <p className={styles.contactUsText}>{title}</p>
              <p className={styles.contactUsPhoneNumber}>
                <strong>{subtitle}</strong>
              </p>
            </div>
          </div>
        </Link>
      )}
    </header>
  );
};
