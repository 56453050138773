import { format } from 'date-fns';
import Stars from '@quno/patient-journey/src/components/ui/Stars';
import RichText from '@quno/patient-journey/src/components/ui/ContentBlocks/RichText';
import { useTemplateWords } from '@quno/patient-journey/src/hooks/useTemplateWords';
import { Card } from '@quno/patient-journey/src/components/Card/Card';
import styles from './ReviewCard.module.scss';
import type { CardProps } from '@quno/patient-journey/src/components/Card/card.config';

export type ReviewCardProps = {
  rating?: number | null;
  date: string;
  title?: string | null;
  footer?: string | null;
};

export const ReviewCard = ({
  text,
  rating = 0,
  date,
  title,
  footer,
  backgroundColorOverwrite,
  darkModeEnabled,
  fontColorOverride,
  cardBorderRadius,
}: CardProps & ReviewCardProps): JSX.Element => {
  const { replaceTemplateWords } = useTemplateWords();

  return (
    <Card
      className={styles.item}
      backgroundColorOverwrite={backgroundColorOverwrite}
      darkModeEnabled={darkModeEnabled}
      fontColorOverride={fontColorOverride}
      style={{ borderRadius: cardBorderRadius || 0 }}
    >
      <div className={styles.meta}>
        <div>
          <Stars rating={parseFloat((rating || 0).toFixed(1))} />
        </div>
        <small className={styles.date}>
          {!!date && (
            <time dateTime={date}>
              {format(new Date(date), 'LLLL d, yyyy')}
            </time>
          )}
        </small>
      </div>
      <h4
        className={styles.title}
        style={{ color: fontColorOverride || undefined }}
      >
        {replaceTemplateWords(title!)}
      </h4>
      <p
        className={styles.customer}
        style={{ color: fontColorOverride || undefined }}
      >
        {footer}
      </p>
      <div>
        <RichText fields={text} noWrapper />
      </div>
    </Card>
  );
};
