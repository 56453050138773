import RichText from '@quno/patient-journey/src/components/ui/ContentBlocks/RichText';
import { useTemplateWords } from '@quno/patient-journey/src/hooks/useTemplateWords';
import { Card } from '@quno/patient-journey/src/components/Card/Card';
import styles from './PhotoCard.module.scss';
import type { CardProps } from '@quno/patient-journey/src/components/Card/card.config';

export type PhotoCardProps = {
  image?: JSX.Element | null;
  title?: string | null;
};

export const PhotoCard = ({
  text,
  image,
  title,
  backgroundColorOverwrite,
  fontColorOverride,
}: CardProps & PhotoCardProps): JSX.Element => {
  const { replaceTemplateWords } = useTemplateWords();

  return (
    <Card
      className={styles.item}
      alignment="center"
      backgroundColorOverwrite={backgroundColorOverwrite}
      fontColorOverride={fontColorOverride}
    >
      <div className={styles.imageWrapper}>{image}</div>
      <h4 className={styles.title}>{replaceTemplateWords(title!)}</h4>
      <div>
        <RichText fields={text} noWrapper />
      </div>
    </Card>
  );
};
