import { SwipeableContainer } from '@quno/patient-journey/src/components/Container/Variants/SwipeableContainer';
import { BaseContainer } from '@quno/patient-journey/src/components/Container/BaseContainer';
import { renderBlocks } from '@quno/patient-journey/src/contentful/patient-journey/renderBlocks';
import { BlockAccordion } from '@quno/patient-journey/src/contentful/patient-journey/blocks/BlockAccordion';
import type { BlockContainerFragment } from '@quno/patient-journey/src/contentful/generated/graphql-patient-journey';
import type { ContainerProps } from '@quno/patient-journey/src/components/Container/container.config';

export const BlockContainer = (
  props: BlockContainerFragment,
): JSX.Element | null => {
  const {
    backgroundColor,
    backgroundImage,
    backgroundRepeat,
    backgroundColorOpacity,
    backgroundColorOverwrite,
    backgroundStyle,
    darkModeEnabled,
    footerTextAlignment,
    template,
    titleAlignment,
    narrow,
    columns,
    width,
    blocksCollection,
    scrollable,
    title,
    footerText,
    fontColorOverride,
    accordionFontColor,
    accordionBackgroundColor,
  } = props;

  const blocks = blocksCollection?.items || [];

  const containerProps = {
    backgroundColor: backgroundColor as ContainerProps['backgroundColor'],
    backgroundColorOpacity:
      backgroundColorOpacity as ContainerProps['backgroundColorOpacity'],
    backgroundColorOverwrite:
      backgroundColorOverwrite as ContainerProps['backgroundColorOverwrite'],
    backgroundImage,
    backgroundRepeat: !!backgroundRepeat,
    backgroundStyle: backgroundStyle as ContainerProps['backgroundStyle'],
    titleAlignment: titleAlignment as ContainerProps['titleAlignment'],
    title,
    darkModeEnabled: darkModeEnabled || false,
    footerText,
    footerTextAlignment:
      footerTextAlignment as ContainerProps['footerTextAlignment'],
    hasTitle: !!title,
    columns,
    narrow,
    width: width as ContainerProps['width'],
    scrollable: !!scrollable,
    fontColorOverride,
  };

  const origin = blocks.map((block) => {
    const originalBlock = props.blocksCollection?.items.find(
      (b) => b?.sys.id === block?.sys.id,
    );

    return originalBlock || block;
  });

  const content = renderBlocks(origin, { variant: template });

  if (['accordion'].includes(template as string) && !scrollable) {
    return (
      <BaseContainer {...containerProps}>
        <BlockAccordion
          sys={props.sys}
          __typename="BlockAccordion"
          itemsCollection={{
            __typename: 'BlockAccordionItemsCollection',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            items: origin.map((block: any) => ({
              __typename: 'BlockAccordionItem',
              sys: block?.sys,
              title: block?.title,
              content: block?.description,
            })),
          }}
          isFaq
          fontColorOverride={accordionFontColor || ''}
          backgroundColorOverwrite={accordionBackgroundColor || ''}
        />
      </BaseContainer>
    );
  }

  if (
    [
      'before_after_photos',
      'review',
      'doctor',
      'photo',
      'logos',
      'product',
      'accordion',
      'treatment',
      'text',
      'image',
      'card',
    ].includes(template as string)
  ) {
    if (scrollable) {
      return (
        <SwipeableContainer {...containerProps}>{content}</SwipeableContainer>
      );
    }

    return <BaseContainer {...containerProps}>{content}</BaseContainer>;
  }

  return null;
};
