import { FunnelCalendarBooking } from '@quno/patient-journey/src/components/CalendarBooking/FunnelCalendarBooking';
import type { Package } from './BlockCard';
import type { BlockCalendarBookingFragment } from '../../generated/graphql-patient-journey';

export const BlockCalendarBooking = (
  props: BlockCalendarBookingFragment,
): JSX.Element | null => {
  const eventSetupId = props.eventSetupId as string;
  const hideDoctors = props.hideDoctors as boolean;
  const funnelId = props.funnelId as string;
  const titleOverride = props.titleOverride as string;
  const index = props.package?.sys?.urn?.lastIndexOf('/') || -1;
  const contentfulPackageId = props.package?.sys?.urn?.substring(index + 1);
  const contentfulPackage = props.package
    ? { sys: { id: contentfulPackageId } }
    : undefined;

  return (
    <FunnelCalendarBooking
      eventSetupId={eventSetupId}
      funnelId={funnelId}
      contentfulPackage={contentfulPackage as Package}
      titleOverride={titleOverride}
      hideDoctors={hideDoctors}
    />
  );
};
