import clsx from 'clsx';
import { textColorClass } from '@quno/patient-journey/src/theme/textColorClass';
import { backgroundColorClass } from '@quno/patient-journey/src/theme/backgroundColorClass';
import { BLUR_IMAGE_WIDTH } from '@quno/patient-journey/src/constants/image';
import { getOpacity } from '@quno/patient-journey/src/utils/opacity';
import { backgroundStyleClass } from '@quno/patient-journey/src/theme/backgroundStyleClass';
import { useTemplateWords } from '@quno/patient-journey/src/hooks/useTemplateWords';
import { createCloudimgLoader } from '@quno/patient-journey/src/utils/cloudimg';
import styles from './BaseContainer.module.scss';
import type { ContainerProps } from './container.config';

export const BaseContainer = ({
  children,
  backgroundColor,
  backgroundImage,
  backgroundRepeat,
  backgroundStyle,
  backgroundColorOverwrite,
  backgroundColorOpacity,
  title,
  titleAlignment,
  footerText,
  footerTextAlignment,
  darkModeEnabled,
  isSwipeContainer,
  hasTitle,
  columns,
  width,
  fontColorOverride,
}: ContainerProps): JSX.Element => {
  const { replaceTemplateWords } = useTemplateWords();
  const opacity = getOpacity(backgroundColorOpacity!);

  const url = createCloudimgLoader()({
    src: backgroundImage?.url || '',
    width:
      backgroundStyle === 'blur'
        ? BLUR_IMAGE_WIDTH
        : backgroundImage?.width || undefined,
    quality: 75,
  });

  return (
    <div
      className={clsx(
        styles.container,
        isSwipeContainer && styles.swipeContainer,
        width === 'full' && styles.fullContainer,
        width === 'normal' && styles.normalContainer,
        width === 'narrow' && styles.narrowContainer,
      )}
    >
      <div
        style={{
          backgroundImage: backgroundImage ? `url(${url})` : undefined,
        }}
        className={clsx(
          styles.backgroundLayer,
          backgroundStyle === 'blur' && backgroundStyleClass('blur'),
          backgroundRepeat && styles.backgroundRepeat,
        )}
      />

      <div
        className={clsx(
          styles.backgroundLayer,
          backgroundStyle !== 'default' && [
            backgroundColorClass(backgroundColor),
          ],
        )}
        style={{
          backgroundColor: backgroundColorOverwrite || undefined,
          opacity: opacity || 1,
        }}
      />

      <div
        className={clsx(styles.innerContainer, textColorClass(darkModeEnabled))}
      >
        {title && (
          <h2
            className={clsx(styles.title, styles[titleAlignment || 'left'])}
            style={
              { '--title-color': fontColorOverride } as React.CSSProperties
            }
          >
            {replaceTemplateWords(title!)}
          </h2>
        )}
        <div
          className={clsx(
            styles.wrapper,
            isSwipeContainer && styles.scrollable,
            !footerText && styles.bottomPadding,
            hasTitle && styles.topPadding,
            width === 'full' && styles.fullWrapper,
            width === 'normal' && styles.normalWrapper,
            width === 'narrow' && styles.narrowWrapper,
          )}
          style={
            {
              '--container-cols': columns || 3,
            } as React.CSSProperties
          }
        >
          {children}
        </div>
        {footerText && (
          <p
            className={clsx(
              styles.footer,
              styles[footerTextAlignment || 'left'],
            )}
          >
            <small>{replaceTemplateWords(footerText)}</small>
          </p>
        )}
      </div>
    </div>
  );
};
