import { PagesProvider, getPageInOrder } from '@quno/quno-gen';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Fragment, useEffect, useState } from 'react';
import { MetaTags } from '@quno/patient-journey/src/components/shared/MetaTags/MetaTags';
import renderBlock from '@quno/patient-journey/src/contentful/patient-journey/renderBlocks';
import { mapRichTextToBlocks } from '@quno/patient-journey/src/contentful/mappers/mapRichTextToBlocks';
import { Header } from '@quno/patient-journey/src/components/Header/Header';
import { Footer } from '@quno/patient-journey/src/components/Footer/Footer';
import { Layout } from '@quno/patient-journey/src/components/shared/Layout/Layout';
import { ModalContext } from '@quno/patient-journey/src/context/ModalContext';
import { Modal } from '@quno/patient-journey/src/components/shared/Modal';
import { useRouter } from 'next/router';
import { findLeadOrOpportunity } from '@quno/patient-journey/src/data/fetch/lead-or-opportunity';
import { richTextLineBreakConfig } from '@quno/patient-journey/src/contentful/patient-journey/richTextLineBreakConfig';
import { PatientContext } from '@quno/patient-journey/src/context/PatientContext';
import { ExistingQuoteModal } from '@quno/patient-journey/src/components/shared/Modals/ExistingQuoteModal';
import type { PatientContextProps } from '@quno/patient-journey/src/context/PatientContext';
import type { Brand } from '@quno/patient-journey/src/contentful/generated/graphql';
import type { ModalContextProps } from '@quno/patient-journey/src/context/ModalContext';
import type { BackgroundColor } from '@quno/patient-journey/src/theme/backgroundColorClass';
import type { Page as PageFragment } from '@quno/patient-journey/src/contentful/generated/graphql-patient-journey';

export const Page = (props: PageFragment): JSX.Element => {
  const pages = getPageInOrder(props as object) as PageFragment[];
  const page = pages[0] as PageFragment;
  const brand = page.brand as unknown as Brand;
  const [modal, setModal] = useState<ModalContextProps | null>(null);

  const [patient, setPatient] = useState<PatientContextProps | null>(null);

  const router = useRouter();

  const junoId = router.query?.junoid;

  useEffect(() => {
    const run = async (): Promise<void> => {
      const leadOrOpportunityExists = await findLeadOrOpportunity(
        junoId as string,
      );

      if (!leadOrOpportunityExists) {
        return;
      }

      if (leadOrOpportunityExists.quotes?.length) {
        setModal({
          isOpen: true,
          showCloseButton: false,
          showPrevIcon: false,
          content: (
            <ExistingQuoteModal
              quote={leadOrOpportunityExists.quotes?.[0]}
              junoId={junoId as string}
            />
          ),
          onModalClose() {
            void router.replace(
              `/${router.locale}/${router.asPath.split('?')[0]}`,
            );
          },
        });

        return;
      }

      setPatient({
        junoId: junoId as string,
        firstName: leadOrOpportunityExists?.data.firstName || '',
        lastName: leadOrOpportunityExists?.data.lastName || '',
      });
    };

    if (junoId && brand.settings.allowJunoId) {
      void run();
    }
  }, [junoId]);

  const {
    blocksCollection,
    footerSocialLinks,
    footerBackgroundColor,
    footerBackgroundColorOpacity,
    footerBackgroundColorOverwrite,
    footerDarkModeEnabled,
    footerQunosuitePoweredBadge,
    headerCta,
    footerAddress,
    footerContact,
    seoTitle,
    seoDescription,
    footerFontColorOverride,
  } = props;

  const content = blocksCollection?.items.map((block, i) => {
    const originalBlock = props.blocksCollection?.items.find(
      (b) => b?.sys.id === block?.sys.id,
    );

    return (
      <Fragment key={i}>
        {renderBlock(originalBlock || block, {
          blocks: blocksCollection.items || [],
        })}
      </Fragment>
    );
  });

  const address = documentToReactComponents(
    footerAddress?.json,
    richTextLineBreakConfig,
  );
  const contactNumber = documentToReactComponents(
    footerContact?.json,
    richTextLineBreakConfig,
  );
  const [socialLinks] = mapRichTextToBlocks(footerSocialLinks);

  const backgroundColor = footerBackgroundColor as BackgroundColor;
  const darkModeEnabled = !!footerDarkModeEnabled;

  const [cta] = mapRichTextToBlocks(headerCta);

  const header = (
    <Header
      cta={{
        icon: cta?.asset
          ? {
              src: cta.asset?.url,
              alt: cta.asset?.title,
            }
          : undefined,
        title: cta?.p,
        subtitle: cta?.hyperlink?.text,
        href: cta?.hyperlink?.href,
      }}
    />
  );

  const hasFooter =
    footerAddress?.json ||
    footerContact?.json ||
    footerSocialLinks?.json ||
    footerQunosuitePoweredBadge;

  const footer = hasFooter ? (
    <Footer
      address={address}
      contactNumber={contactNumber}
      socialLinks={socialLinks}
      backgroundColor={backgroundColor}
      backgroundColorOpacity={footerBackgroundColorOpacity as string}
      backgroundColorOverwrite={footerBackgroundColorOverwrite as string}
      darkModeEnabled={darkModeEnabled}
      qunosuitePoweredBadge={footerQunosuitePoweredBadge}
      fontColorOverride={footerFontColorOverride}
    />
  ) : undefined;

  return (
    <PagesProvider pages={pages}>
      <MetaTags
        title={seoTitle}
        description={seoDescription}
        ogImage={brand?.ogImage?.url}
        ogDescription={seoDescription}
        favicon={brand?.favicon?.url}
      />

      <Layout header={header} footer={footer}>
        <ModalContext.Provider value={{ modal, setModal }}>
          <PatientContext.Provider value={{ patient, setPatient }}>
            {content}
            <Modal />
          </PatientContext.Provider>
        </ModalContext.Provider>
      </Layout>
    </PagesProvider>
  );
};
