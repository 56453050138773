import { useTranslation } from '@quno/patient-journey/src/hooks/useTranslation';
import Script from 'next/script';
import { useContext, useEffect, useState } from 'react';
import { createUniqueId } from '@quno/patient-journey/src/utils/createUniqueId';
import { FunnelContext } from '@quno/patient-journey/src/context/FunnelContext';
import type { FC } from 'react';
import type { ExternalFunnelConfig } from './ExternalFunnelConfig';

export type ExternalFunnelProps = Omit<ExternalFunnelConfig, 'locale'>;

type Props = ExternalFunnelProps & {
  preload?: boolean;
  onScriptLoaded?: () => void;
  beforeInteractive?: boolean;
};

const ExternalFunnel: FC<Props> = ({
  preload,
  onScriptLoaded,
  beforeInteractive,
  ...externalFunnelConfig
}) => {
  const { locale } = useTranslation();

  const { externalFunnelLoaded, setExternalFunnelLoaded } =
    useContext(FunnelContext);

  const [funnelContainerId, setFunnelContainerId] =
    // null is not a valid id
    useState<string | undefined>();

  useEffect(() => {
    // useEffect is needed to prevent a warning saying that the id is different
    // between client and server
    setFunnelContainerId(createUniqueId());
  }, []);

  useEffect(() => {
    if (externalFunnelLoaded) {
      onScriptLoaded?.();
    }
  }, [externalFunnelLoaded]);

  useEffect(() => {
    if (!externalFunnelLoaded || !funnelContainerId) {
      return;
    }

    if (preload) {
      void window.loadExternalFunnel(
        externalFunnelConfig.funnelId,
        locale,
        externalFunnelConfig.configFromSameDomain,
      );
    } else {
      void window.registerExternalFunnel(
        {
          ...externalFunnelConfig,
          locale,
        },
        funnelContainerId,
      );
    }
  }, [
    externalFunnelLoaded,
    funnelContainerId,
    preload,
    externalFunnelConfig,
    locale,
  ]);

  const scriptProps =
    beforeInteractive && process.env.NEXT_PUBLIC_VERCEL_ENV === 'development'
      ? {
          strategy: 'beforeInteractive' as const,
          onReady() {
            setExternalFunnelLoaded(true);
          },
        }
      : {
          onLoad() {
            setExternalFunnelLoaded(true);
          },
        };

  return (
    <>
      {!preload && <div id={funnelContainerId} className="external-funnel" />}
      {/* eslint-disable-next-line @next/next/no-before-interactive-script-outside-document */}
      <Script
        id="external-funnel"
        type="module"
        src="/funnel/funnel-v13.js"
        {...scriptProps}
      />
    </>
  );
};

export default ExternalFunnel;
