import { HeroSimple } from '@quno/patient-journey/src/components/Hero/HeroSimple';
import { HeroFunnel } from '@quno/patient-journey/src/components/Hero/HeroFunnel';
import { mapRichTextToBlocks } from '@quno/patient-journey/src/contentful/mappers/mapRichTextToBlocks';
import type {
  BlockHeroFragment,
  CtaFragment,
  PageBlocksCollection,
} from '@quno/patient-journey/src/contentful/generated/graphql-patient-journey';
import type { BackgroundColor } from '@quno/patient-journey/src/theme/backgroundColorClass';
import type { BackgroundStyle } from '@quno/patient-journey/src/theme/backgroundStyleClass';
import type { HeroProps } from '@quno/patient-journey/src/components/Hero/hero.config';

export type BlockHeroVariant = 'simple' | 'funnel';

export const BlockHero = (
  props: BlockHeroFragment & {
    variant: BlockHeroVariant;
    isPreview?: boolean; // This is a custom field that is not part of the content model but is used for the onboarding preview
    funnelLocale?: string; // This is a custom field that is not part of the content model but is used for the onboarding preview
    blocks?: PageBlocksCollection['items']; // This is a custom field that is not part of the content model but is used for preload and preconnect
  },
): JSX.Element => {
  const {
    variant,
    imageDetailPosition,
    centerContent,
    flipContent,
    darkModeEnabled,
    backgroundColor,
    backgroundColorOpacity,
    backgroundColorOverwrite,
    backgroundStyle,
    backgroundRepeat,
    funnel,
    cardOnMobile,
    image,
    textContent,
    imageBorderRadius,
    fontColorOverride,
    isPreview,
    funnelLocale,
    blocks,
  } = props;

  const heroAndFunnelIndex = blocks?.findIndex(
    (block) => block?.__typename === 'BlockHero' && block.variant === 'funnel',
  );
  const isFirstBlock = heroAndFunnelIndex === 0;

  const imageAttrs = image
    ? {
        src: image.url!,
        alt: image.title!,
        width: image.width!,
        height: image.height!,
      }
    : undefined;

  const funnelUrn = funnel?.sys.urn;

  const heroBaseProps = {
    backgroundRepeat: !!backgroundRepeat,
    backgroundColor: backgroundColor as BackgroundColor,
    backgroundColorOpacity:
      backgroundColorOpacity as HeroProps['backgroundColorOpacity'],
    backgroundColorOverwrite:
      backgroundColorOverwrite as HeroProps['backgroundColorOverwrite'],
    darkModeEnabled: !!darkModeEnabled,
    flipContent: !!flipContent,
    centerContent: !!centerContent,
    image: imageAttrs,
    text: textContent?.json,
    imageBorderRadius: imageBorderRadius as HeroProps['imageBorderRadius'],
    fontColorOverride: fontColorOverride as HeroProps['fontColorOverride'],
  };

  const [cta] = mapRichTextToBlocks(textContent);

  if (variant === 'funnel' && funnelUrn) {
    const index = funnelUrn.lastIndexOf('/');
    const funnelId = funnelUrn.substring(index + 1);

    return (
      <HeroFunnel
        funnelId={funnelId}
        ctas={cta?.entries as unknown as CtaFragment[]}
        backgroundStyle={backgroundStyle as BackgroundStyle}
        locale={funnelLocale}
        isPreview={isPreview}
        isFirstBlock={isFirstBlock}
        {...heroBaseProps}
      />
    );
  }

  return (
    <HeroSimple
      cardOnMobile={cardOnMobile}
      ctas={cta?.entries as unknown as CtaFragment[]}
      imageDetailPosition={
        imageDetailPosition as HeroProps['imageDetailPosition']
      }
      {...heroBaseProps}
    />
  );
};
