import RichText from '@quno/patient-journey/src/components/ui/ContentBlocks/RichText';
import { useTemplateWords } from '@quno/patient-journey/src/hooks/useTemplateWords';
import clsx from 'clsx';
import { Card as QdsCard } from '@quno/patient-journey/src/components/Card/Card';
import styles from './Card.module.scss';
import type { CardProps as QdsCardProps } from '@quno/patient-journey/src/components/Card/card.config';

export type CardProps = {
  image?: JSX.Element | null;
  title?: string | null;
  footer?: string | null;
};

export const Card = ({
  text,
  image,
  title,
  footer,
  backgroundColorOverwrite,
  darkModeEnabled,
  imageStyle,
  imagePosition,
  cardBorderRadius,
  fontColorOverride,
}: QdsCardProps & CardProps): JSX.Element => {
  const { replaceTemplateWords } = useTemplateWords();

  const alignSelfMapper = (position: 'left' | 'center'): string => {
    switch (position) {
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
      default:
        return 'flex-start';
    }
  };

  return (
    <QdsCard
      className={clsx(styles.item, styles[(imageStyle as string) || 'circle'])}
      footer={footer}
      backgroundColorOverwrite={backgroundColorOverwrite}
      darkModeEnabled={darkModeEnabled}
      style={{ borderRadius: cardBorderRadius || 0 }}
      fontColorOverride={fontColorOverride}
    >
      <div
        className={styles.imageWrapper}
        style={{
          alignSelf: alignSelfMapper(imagePosition as 'left' | 'center'),
        }}
      >
        {image}
      </div>
      <div className={styles.text}>
        <h4
          className={styles.title}
          style={{
            color: fontColorOverride,
          }}
        >
          {replaceTemplateWords(title!)}
        </h4>
        <div>
          <RichText fields={text} noWrapper />
        </div>
      </div>
    </QdsCard>
  );
};
