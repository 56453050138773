import { useTranslation } from '@quno/patient-journey/src/hooks/useTranslation';
import { useContext, useEffect, useRef } from 'react';
import { createUniqueId } from '@quno/patient-journey/src/utils/createUniqueId';
import { FunnelContext } from '@quno/patient-journey/src/context/FunnelContext';
import type { FC } from 'react';
import type { ExternalFunnelConfig } from './ExternalFunnelConfig';

export type ExternalFunnelProps = ExternalFunnelConfig;

type Props = ExternalFunnelProps & {
  preload?: boolean;
  onScriptLoaded?: () => void;
  beforeInteractive?: boolean;
  setFunnel?: (funnel: HTMLDivElement) => void;
};

const ExternalFunnel: FC<Props> = ({
  preload,
  onScriptLoaded,
  beforeInteractive,
  locale: funnelLocale,
  setFunnel,
  ...externalFunnelConfig
}) => {
  const { locale: pageLocale } = useTranslation();
  const locale = funnelLocale || pageLocale;

  const funnelContainerIdRef = useRef<string | undefined>(undefined);
  const funnelContainerRef = useRef<HTMLDivElement | null>(null);

  const { externalFunnelLoaded, setExternalFunnelLoaded } =
    useContext(FunnelContext);

  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'module';
    scriptTag.src = '/funnel/funnel-v13.js';
    scriptTag.id = 'external-funnel';
    scriptTag.addEventListener('load', () => {
      setExternalFunnelLoaded(true);
    });

    document.body.appendChild(scriptTag);

    return () => {
      scriptTag.removeEventListener('load', () => {
        setExternalFunnelLoaded(false);
      });

      scriptTag?.parentNode?.removeChild(scriptTag);
    };
  }, []);

  useEffect(() => {
    const previousFunnelContainer = funnelContainerRef.current;
    if (previousFunnelContainer) {
      previousFunnelContainer.remove();
    }

    funnelContainerIdRef.current = createUniqueId();
    const newFunnelContainer = document.createElement('div');
    newFunnelContainer.id = funnelContainerIdRef.current;
    newFunnelContainer.className = 'external-funnel';

    funnelContainerRef.current = newFunnelContainer;
    setFunnel?.(newFunnelContainer);
  }, [locale]);

  useEffect(() => {
    if (externalFunnelLoaded) {
      onScriptLoaded?.();
    }
  }, [externalFunnelLoaded]);

  useEffect(() => {
    if (
      !externalFunnelLoaded ||
      !funnelContainerIdRef.current ||
      !funnelContainerRef.current
    ) {
      return;
    }

    if (preload) {
      void window.loadExternalFunnel(
        externalFunnelConfig.funnelId,
        locale,
        externalFunnelConfig.configFromSameDomain,
      );
    } else {
      void window.registerExternalFunnel(
        {
          ...externalFunnelConfig,
          locale,
        },
        funnelContainerIdRef.current,
      );
    }
  }, [
    externalFunnelLoaded,
    funnelContainerIdRef.current,
    preload,
    externalFunnelConfig,
    locale,
  ]);

  return <></>;
};

export default ExternalFunnel;
