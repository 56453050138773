import { useState } from 'react';
import clsx from 'clsx';
import RichText from '@quno/patient-journey/src/components/ui/ContentBlocks/RichText';
import Icon from '@quno/patient-journey/src/components/ui/Icon';
import styles from './Accordion.module.scss';
import type { JsonRichText } from '@quno/patient-journey/types/Utilities';

export type AccordionItemProps = {
  title: string;
  content?: Document | JsonRichText;
  fontColorOverride?: string;
  backgroundColorOverwrite?: string;
};

export const AccordionItem = ({
  title,
  content,
  fontColorOverride,
  backgroundColorOverwrite,
}: AccordionItemProps): JSX.Element => {
  const [showItem, setShowItem] = useState(false);

  return (
    <div
      onClick={() => {
        setShowItem(!showItem);
      }}
      className={clsx(styles.item, styles.arrow, showItem && styles.arrowOpen)}
      style={{ backgroundColor: backgroundColorOverwrite }}
    >
      <h3 className={styles.title}>
        <span style={{ color: fontColorOverride }}>{title}</span>
        <Icon type="Chevron" color="#15202B" width={22} height={12} />
      </h3>
      {showItem && (
        <div className={styles.content}>
          <RichText
            fields={content as JsonRichText}
            noWrapper
            style={{ color: fontColorOverride }}
          />
        </div>
      )}
    </div>
  );
};
